import Logo from '@assets/images/logo_dark.svg'
import ButtonAppMarket from '@src/components/ButtonAppMarket'
import PageWrap from '@src/components/PageWrap'
import { PHONE_NUMBER } from '@src/constants'
import { metrikaGoal } from '@src/utils/metrikaGoal'
import { Block, Divider, Flexbox, Link, Paragraph } from '@stage-ui/core'

export const Footer = () => {
  return (
    <Block backgroundColor="gray800" py="3.5rem" mt="5.5rem">
      <PageWrap>
        <Flexbox direction={['row', 'column']} alignItems="center">
          <Link
            href="/"
            onClick={() => {
              metrikaGoal('clickFooderLogo')
            }}
          >
            <img src={Logo} style={{ width: '10rem' }} />
          </Link>
          <Flexbox
            column
            centered={[false, true]}
            my={[0, '2rem']}
            flex={1}
            ml={['5.5rem', 0]}
          >
            <Link
              decoration="none"
              color="white"
              size={'1.25rem'}
              weight={700}
              href={`tel:${PHONE_NUMBER}`}
            >
              {PHONE_NUMBER}
            </Link>
            <Paragraph color="gray300" weight={500} my="s">
              ОГРН 1207700169192 / ИНН 9729296352
            </Paragraph>
            <Paragraph
              align={['start', 'start', 'center']}
              color="gray400"
              weight={300}
              mb="s"
            >
              119285, г. Москва, км Мжд Киевское 5-й, д. 5, стр. 43, этаж 1 каб 4
            </Paragraph>
          </Flexbox>
          <Flexbox>
            <ButtonAppMarket
              mr="m"
              type="apple"
              style={{
                opacity: 1,
                filter: 'invert(1)',
              }}
              onClick={() => {
                metrikaGoal('clickFooterAppDownloadApple')
              }}
            />
            <ButtonAppMarket
              type="google"
              style={{
                opacity: 1,
                filter: 'invert(1)',
              }}
              onClick={() => {
                metrikaGoal('clickFooterAppDownloadGoogle')
              }}
            />
          </Flexbox>
        </Flexbox>
        <Divider color="gray700" my="xl" />
        <Paragraph color="gray400">
          {`© 2020-${new Date().getFullYear()} | `}
          <Link
            color="gray400"
            decoration="none"
            href="/policy"
            onClick={() => {
              metrikaGoal('clickFooterPrivacyLink')
            }}
          >
            Политика конфиденциальности
          </Link>
        </Paragraph>
      </PageWrap>
    </Block>
  )
}
