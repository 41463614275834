import { light } from '@stage-ui/core/themes'

const theme = light.replace({
  main: {
    radius: {
      xl: '0.75rem',
      l: '0.5rem',
      m: '0.375rem',
      s: '0.25rem',
      xs: '0.125rem',
    },
    breakpoints: ['1200px', '1008px', '768px', '576px'],
    color: {
      primary: [5, 150, 105], // rgb(130, 23, 23) green600
      onPrimary: [255, 255, 255], // rgb(255, 255, 255)
      success: [155, 197, 61], // rgb(155,197,61)
      error: [255, 90, 95], // rgb(255,90,95)
      info: [28, 185, 252], // rgb(28,185,252)
      warning: [252, 162, 28], // rgb(252,162,28)
      secondary: [28, 171, 252], // rgb(28,171,252)
      onSecondary: [255, 255, 255], // rgb(255,255,255)
      background: [250, 250, 250], // rgb(250,250,250)
      backgroundVariant: [243, 242, 242], // rgb(243,242,242)
      onBackground: [41, 30, 15], // rgb(41,30,15)
      surface: [255, 255, 255], // rgb(255, 255, 255)
      surfaceVariant: [249, 243, 234], // rgb(249,243,234)
      onSurface: [35, 35, 35], // rgb(35,35,35)
      light: [191, 191, 191], // rgb(191,191,191)
      lightest: [243, 242, 241], // rgb(243,242,241)
      white: [255, 255, 255],
      // palette: {
      //     warmGray50: [242, 242, 242],
      //     warmGray100: [230, 230, 230],
      //     warmGray200: [217, 217, 217],
      //     warmGray300: [205, 205, 205],
      //     warmGray400: [192, 192, 192],
      //     warmGray500: [180, 180, 180],
      //     warmGray600: [167, 167, 167],
      //     warmGray700: [155, 155, 155],
      //     warmGray800: [142, 142, 142],
      //     warmGray900: [130, 130, 130],
      // },
      // warmGray: {
      //     50: [242, 242, 242],
      //     100: [230, 230, 230],
      //     200: [217, 217, 217],
      //     300: [205, 205, 205],
      //     400: [192, 192, 192],
      //     500: [180, 180, 180],
      //     600: [167, 167, 167],
      //     700: [155, 155, 155],
      //     800: [142, 142, 142],
      //     900: [130, 130, 130],
      // },
      // warmGray50:  [242, 242, 242],
      // warmGray100: [230, 230, 230],
      // warmGray200: [217, 217, 217],
      // warmGray300: [205, 205, 205],
      // warmGray400: [192, 192, 192],
      // warmGray500: [180, 180, 180],
      // warmGray600: [167, 167, 167],
      // warmGray700: [155, 155, 155],
      // warmGray800: [142, 142, 142],
      // warmGray900: [130, 130, 130],
    },
  },
  assets: () => ({
    focus: {
      outline: 'none',
      boxShadow: '',
    },
    typography: {
      text: {
        xl: {
          fontSize: '1.5rem',
          lineHeight: '1.75rem',
        },
        l: {
          fontSize: '1.25rem',
          lineHeight: '1.5rem',
        },
        m: {
          fontSize: '1rem',
          lineHeight: '1.5rem',
        },
        s: {
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
        },
        xs: {
          fontSize: '0.75rem',
          lineHeight: '1rem',
        },
      },
      header: {
        xl: {
          fontSize: '2.5rem',
          lineHeight: '3.5rem',
          margin: 0,
        },
        l: {
          fontSize: '2rem',
          lineHeight: '3rem',
          margin: 0,
        },
        m: {
          fontSize: '1.5rem',
          lineHeight: '2rem',
          margin: 0,
        },
        s: {
          fontSize: '1.25rem',
          lineHeight: '1.5rem',
          margin: 0,
        },
        xs: {
          fontSize: '1.125rem',
          lineHeight: '1.5rem',
          margin: 0,
        },
      },
    },
    field: {
      xs: {
        indent: '0.5rem',
        height: '1.5rem',
      },
      s: {
        indent: '0.75rem',
        height: '2rem',
      },
      m: {
        indent: '1rem',
        height: '2.5rem',
      },
      l: {
        indent: '1.25rem',
        height: '3rem',
      },
      xl: {
        indent: '1.5rem',
        height: '3.5rem',
      },
    },
  }),
  overrides: (main, assets) => ({
    Modal: {
      window: (state) => [
        {
          padding: '1.5rem',
          borderRadius: main.radius.m,
        },
        state.decoration === 'panel' && {
          borderRadius: `${main.radius.xl} ${main.radius.xl} 0 0`,
        },
      ],
      cross: () => ({
        color: main.color.gray[500].hex(),
      }),
      header: () => ({
        marginBottom: main.spacing.m,
        h1: {
          ...assets.typography.header.xs,
          color: main.color.gray[900].hex(),
          lineHeight: '2rem',
        },
        p: {
          ...assets.typography.text.s,
          color: main.color.gray[500].hex(),
          fontWeight: 500,
          marginBottom: 0,
        },
      }),
    },
    Button: (props) => ({
      container: [
        {
          fontWeight: 500,
          paddingLeft: '1.25rem',
          paddingRight: '1.25rem',
        },
        props.size === 'xl' && {
          fontSize: '1.125rem',
          lineHeight: '1.5rem',
        },
        props.size === 'l' && {
          fontSize: '1.125rem',
          lineHeight: '1.5rem',
        },
        props.size === 's' && {
          paddingLeft: '1rem',
          paddingRight: '1rem',
        },
        props.shape === 'rounded' && {
          borderRadius: theme.radius.m,
        },
      ],
    }),
    ScrollView: {
      yThumb: () => [
        {
          background: main.color.gray[500].hex(),
        },
      ],
      yBar: () => [
        {
          backgroundColor: main.color.gray[200].hex(),
        },
      ],
    },
    Field: {
      field: [
        {
          boxShadow: 'none',
        },
      ],
    },
    TextField: {
      container: [
        {
          color: main.color.gray[900].hex(),
          fontWeight: 600,
        },
      ],
      input: {
        '::placeholder': {
          color: main.color.gray[300].hex(),
        },
      },
    },
    Select: {
      option: {
        backgroundColor: main.color.onPrimary.hex(),
        color: `${main.color.gray[600].hex()}`,
        ':hover': {
          color: `${main.color.onPrimary.hex()} !important`,
          backgroundColor: `${main.color.green[400].hex()} !important`,
        },
      },
      input: {
        '::placeholder': {
          color: main.color.gray[500].hex(),
        },
      },
    },
    DatePicker: {
      input: {
        '::placeholder': {
          color: main.color.gray[500].hex(),
        },
      },
    },
    Checkbox: (props) => ({
      check: () => [
        props.size === 'l' && {
          height: '1.5rem',
        },
        props.size === 's' && {
          borderRadius: main.radius.xs,
        },
      ],
    }),
    Toggle: {
      container: {
        borderColor: main.color.light.hex(),
        backgroundColor: main.color.surface.hex(),
        ':before': {
          borderColor: main.color.lightGreen[300].rgb().string(),
          backgroundColor: main.color.lightGreen[50].rgb().string(),
        },
      },
      option: (state) => [
        {},
        state.selected && {
          color: main.color.lightGreen[800].rgb().string(),
        },
      ],
    },
    Table: {
      container: {
        border: 0,
        backgroundColor: 'transparent',
      },
      row: {
        backgroundColor: main.color.white.hex(),
        ':first-of-type': {
          '> td:first-of-type': {
            borderTopLeftRadius: '0.25rem',
          },
          '> td:last-of-type': {
            borderTopRightRadius: '0.25rem',
          },
        },
        ':last-of-type': {
          '> td': {
            borderBottomWidth: '1px',
          },
          '> td:first-of-type': {
            borderBottomLeftRadius: '0.25rem',
          },
          '> td:last-of-type': {
            borderBottomRightRadius: '0.25rem',
          },
        },
      },
      rowCell: {
        verticalAlign: 'middle',
        padding: '1rem 0.5rem',
        borderTopWidth: '1px',
        borderStyle: 'solid',
        borderColor: main.color.gray[200].hex(),
        ':first-of-type': {
          borderLeftWidth: '1px',
          paddingLeft: '1rem',
        },
        ':last-of-type': {
          borderRightWidth: '1px',
          paddingRight: '1rem',
        },
      },
      headCell: () => [
        {
          color: main.color.gray[500].hex(),
          fontWeight: 500,
          padding: '0.5rem',
          whiteSpace: 'nowrap',
          ...assets.typography.text.xs,
          borderStyle: 'solid',
          borderColor: 'transparent',
          ':first-of-type': {
            borderLeftWidth: '1px',
            paddingLeft: '1rem',
          },
          ':last-of-type': {
            borderRightWidth: '1px',
            paddingRight: '1rem',
          },
        },
      ],
    },
    Switch: {
      check: ({ checked }) => [
        {
          background: main.color.gray[400].hex(),
          width: '2.75rem',
          height: '1.5rem',
          borderRadius: '2rem',
        },
        checked && {
          background: main.color.primary.hex(),
        },
      ],
      icon: ({ checked }) => [
        {
          left: checked ? '0.625rem' : '-0.625rem',
          boxShadow: 'none',
          border: 'none',
        },
      ],
    },
  }),
})

export default theme
